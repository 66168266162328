<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import {TokenService} from "@/services/token.service";
import 'vue-multiselect/dist/vue-multiselect.min.css';
export default {
  data() {
    return {
      user: null
    }
  },
  mounted() {
    ApiService.setHeader()
    ApiService.get('me').then(res => {
      TokenService.setUser(res.data)
      this.user = res.data
      console.log(this.$route.path.indexOf('admin'))
      console.log(this.user.roles.indexOf('ROLE_SUPER_ADMIN'))
      if(this.$route.path.indexOf('admin') > -1 && this.user.roles.indexOf('ROLE_SUPER_ADMIN') === -1 ) {
        window.location.href = '/'
      }
    }).catch(() => {
      if(this.$route.path.indexOf('admin') > -1) {
        window.location.href = '/'
      }
    })
  }
}
</script>

<style lang="scss">
@import "assets/style/main";
.custom-control {
  label {
    margin-left: 10px;
    cursor: pointer;
  }
}
ul {
  list-style: none;
}
</style>
