// @ts-ignore
import axios, { AxiosRequestConfig } from "axios";
import qs from "qs";
import {TokenService} from "./token.service";

const ApiService = {
	_requestInterceptor: 0,
	_401interceptor: 0,

	init(baseURL: string | undefined) {
		axios.defaults.baseURL = baseURL;
	},

	baseURL() {
		return axios.defaults.baseURL;
	},

	setHeader() {
		if(TokenService.getToken()) {
			axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`
		}
	},

	/*removeHeader() {
		axios.defaults.headers.common["Authorization"] = null;
	},*/

	get(resource: string, params= {}) {
		//this.mount401Interceptor();
		return axios.get(resource, params);
	},

	post(resource: string, data: any, withCredentials: true) {
		return axios.post(resource, qs.stringify(data), {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded"
			},
			withCredentials: withCredentials
		});
	},
	postJSON(resource: string, data: any) {
		return axios.post(resource, data, {
			headers: {
				'Content-Type': 'application/json'
			}
		});
	},

	put(resource: string, data: any) {
		return axios.put(resource, data);
	},

	delete(resource: string) {
		return axios.delete(resource);
	},

	customRequest(data: AxiosRequestConfig) {
		return axios(data);
	},

	mountRequestInterceptor() {
		this._requestInterceptor = axios.interceptors.request.use(async config => {
			if(config.method !== 'get') {
				return config
			}
			/*const loading = await loadingController.create({});
			await loading.present();*/
			return config;
		});
	},

	mount401Interceptor() {
		this._401interceptor = axios.interceptors.response.use(
			response => {
				//loadingController.dismiss().then(r => console.log(r));
				return response;
			},
			//TODO: Implement refresh token
			async error => {
				throw error;
			}
		);
	},

	unmount401Interceptor() {
		//axios.interceptors.response.eject(this._401interceptor);
	}
}

export default ApiService;
