const TOKEN_KEY = "access_token";
const USER_KEY = "user";
const REFRESH_TOKEN_KEY = "refresh_token";

const TokenService = {
	getToken() {
		return localStorage.getItem(TOKEN_KEY);
	},
	setToken(accessToken: string) {
		localStorage.setItem(TOKEN_KEY, accessToken);
	},
	getUser() {
		const parse = localStorage.getItem(USER_KEY)
		if(parse) {
			return JSON.parse(parse);
		}
		return null;
	},
	clearUser() {
		return localStorage.removeItem(USER_KEY);
	},
	setUser(user: any) {
		localStorage.setItem(USER_KEY, JSON.stringify(user));
	},
	removeToken() {
		localStorage.removeItem(TOKEN_KEY);
	},
	getRefreshToken() {
		return localStorage.getItem(REFRESH_TOKEN_KEY);
	},
	setRefreshToken(refreshToken: string) {
		localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
	},
	removeRefreshToken() {
		localStorage.removeItem(REFRESH_TOKEN_KEY);
	}
};

export { TokenService };
