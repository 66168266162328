import Vue from 'vue'
// @ts-ignore
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/course/:id',
    name: 'Study',
    component: () => import('../views/Study.vue')
  },
  {
    path: '/study-complete',
    name: 'StudyComplete',
    component: () => import('../views/StudyComplete.vue')
  },
  {
    path: '/courses',
    name: 'Courses',
    component: () => import('../views/Course.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/rki',
    name: 'Rki',
    component: () => import('../views/Rki.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/partners',
    name: 'Partners',
    component: () => import('../views/Partners.vue')
  },
  {
    path: '/certificates',
    name: 'Certificates',
    component: () => import('../views/Certificate.vue')
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('../views/FAQ/FAQ.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/News.vue')
  },
  {
    path: '/news/:id',
    name: 'SingleNews',
    component: () => import('../views/SingleNews.vue')
  },
  {
    path: '/admin',
    component: () => import('../views/admin/CourseListTable.vue'),
    meta: {auth: {roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_METHODIST', 'ROLE_CUSTOMER'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}}
  },
  {
    path: '/admin/stats/:id',
    component: () => import('../views/admin/CourseStatsDetail.vue'),
    meta: {auth: true, redirect: {name: 'login'}}
  },
  {
    path: '/admin/course/:id',
    component: () => import('../views/admin/CourseEdit.vue'),
    children: [
      {
        path: 'module/:index',
        component: () => import('../views/admin/CourseEdit.vue'),
        meta: {auth: true, redirect: {name: 'login'}, forbiddenRedirect: '/403'}
      },
      {
        path: 'students',
        component: () => import('../views/admin/CourseEdit.vue'),
        meta: {auth: true, redirect: {name: 'login'}, forbiddenRedirect: '/403'}
      }
    ],
    meta: {auth: {roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_METHODIST', 'ROLE_CUSTOMER'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}}
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
