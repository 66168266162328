import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ApiService from "./services/api.service";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import moment from "moment"
// @ts-ignore
import VueYandexMetrika from "vue-yandex-metrika"
import VueGtag from "vue-gtag";
import Toasted from 'vue-toasted';
// @ts-ignore
import JsonExcel from "vue-json-excel";

import ElementUI from 'element-ui';
// @ts-ignore
import locale from 'element-ui/lib/locale/lang/ru-RU';
import 'element-ui/lib/theme-chalk/index.css';

Vue.component('downloadExcel', JsonExcel)

Vue.use(Toasted, {
  position: 'top-center',
  fitToScreen: true,
  iconPack: 'fontawesome',
  theme: 'toasted-primary',
  duration: 2000
});

Vue.use(ElementUI, { locale });


import {
  ValidationObserver,
  ValidationProvider,
  withValidation,
  extend,
  localize
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
// @ts-ignore
import ru from "vee-validate/dist/locale/ru.json";
// @ts-ignore
import VueI18n from 'vue-i18n'
import Multiselect from 'vue-multiselect'

Vue.use(VueI18n);
localize("ru", ru);

extend("decimal", {
  // @ts-ignore
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);
    return {
      valid: regex.test(value),
      data: {
        serverMessage: 'Only decimal values are available'
      }
    };
  },
  message: `{serverMessage}`
})

ru.messages.confirmed = 'Пароли не совпадают!';
// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  // @ts-ignore
  extend(rule, rules[rule]);
});

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('withValidation', withValidation);
Vue.component('ValidationProvider', ValidationProvider);

import VueFilterPluralize from '@vuejs-community/vue-filter-pluralize';
Vue.use(VueFilterPluralize);

export const eventBus = new Vue()

Vue.config.productionTip = false
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

ApiService.init(process.env.VUE_APP_ROOT_API);

Vue.filter('formatDate', function(value: any, format = 'DD.MM.YYYY HH:mm') {
  if (value) {
    return moment(String(value)).format(format)
  }
})
// Lets Register a Global Error Notification Toast.
Vue.toasted.register('appError', (message) => {
  if(!Object.keys(message).length) {
    return "Ошибка"
  }
  return message;
}, {
  type : 'error',
  icon : 'exclamation-triangle'
});

Vue.toasted.register('appSuccess',
    (message) => {
      if(!Object.keys(message).length) {
        return "Успешно"
      }
      return message;
    },
    {
      type : 'success',
      icon : 'check'
    }
)
Vue.component('multiselect', Multiselect);
Vue.mixin({
  methods: {
    valid: ({ dirty, validated, valid = null }) => dirty || validated ? valid : null,
    isEnroll: (course) => course.enroll && course.enroll.isActive,
    pathMedia: (media) => media.filePath.indexOf('http') >=-1 ? media.filePath : process.env.VUE_APP_API_URL + media.filePath,
    getRandomInt: () => Math.floor(Math.random() * (100 - 5 + 1)) + 5,
    srcThumb: (media, size = 'big') => media && media.thumbs ? media.thumbs[size] : '/img/no-image.png',
    hashId: () => Math.random().toString(12).substr(2, 9),
    loaderStyle: () => {
      return {
        color: '#18a689',
        loader: 'spinner',
        width: 64,
        height: 64,
        backgroundColor: '#2f4050',
        opacity: 0.5,
        zIndex: 999
      }
    },
    getVariant: (status) => {
      let type;
      switch (status) {
        case 'active':
          type = 'warning';
          break;
        case 'success':
          type = 'primary';
          break;
        case 'failed':
          type = 'danger';
          break;
      }
      return type;
    }
  }
});

Vue.use(VueYandexMetrika, {
  id: 86983919,
  router: router,
  env: process.env.NODE_ENV
  // other options
})

Vue.use(VueGtag, {
  config: { id: "G-SJ7P6D7RZD" }
});

new Vue({
  // @ts-ignore
  router,
  render: h => h(App),
}).$mount('#app')
